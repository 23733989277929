<template>
  <b-container class="mt-2 px-2">
    <validation-observer ref="companyUserForm">

      <!-- #region::Form -->
      <b-form @submit="$event.preventDefault()">
        <b-form-row>

          <!-- #region::Title and close button -->
          <b-col
            md="12"
            class="align-self-center"
          >
            <div class="d-flex justify-content-between">
              <h3 class="align-self-end">
                {{ id ? 'Editar usuario' : 'Nuevo usuario' }}
              </h3>
              <b-button
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                @click="onClose"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
          </b-col>
          <!-- #endregion::Title and close button -->

          <b-col md="12">
            <hr>
          </b-col>

          <!-- #region::Role select -->
          <b-col md="12">
            <SelectWithValidation
              v-model="userType"
              vid="userType"
              name="rol"
              label="Rol"
              property="name"
              placeholder="Selecciona un rol"
              :options="roles"
            />
          </b-col>
          <!-- #endregion::Role select -->

          <!-- #region::CompanyUsers name input -->
          <b-col md="12">
            <TextInputWithValidation
              v-model="fullName"
              vid="fullName"
              rules="required|min:2"
              type="text"
              label="Nombre"
              name="nombre"
              maxlength="200"
              placeholder="Escribe el nombre del usuario"
            />
          </b-col>
          <!-- #endregion::CompanyUsers name input -->

          <!-- #region::Phone input -->
          <b-col md="12">
            <TextInputWithValidation
              v-model="phone"
              vid="phone"
              rules="required|digits:10"
              type="text"
              label="Teléfono"
              name="teléfono"
              placeholder="Escribe el número telefónico"
            />
          </b-col>
          <!-- #endregion::Phone input -->

          <!-- #region::Email input -->
          <b-col md="12">
            <TextInputWithValidation
              v-model="userEmail"
              vid="email"
              rules="required|email_prefix"
              type="text"
              label="Correo electrónico"
              name="correo electrónico"
              append="@optimumequipo.com"
              placeholder="Escribe el correo electrónico"
              :disabled="passwordChanged"
              :append-disabled="true"
            />
          </b-col>
          <!-- #endregion::Email input -->

          <!-- #region::Role select -->
          <b-col
            v-if="userType == 'Coordinador de Almacen'"
            md="12"
          >
            <SelectWithValidation
              v-model="location"
              vid="location"
              name="ubicación"
              label="Ubicación"
              property="name"
              rules="required"
              placeholder="Selecciona una ubicación"
              :options="locations"
            />
          </b-col>
          <!-- #endregion::Role select -->

          <!-- #region::Password input -->
          <b-col
            v-if="id"
            md="12"
          >
            <!-- password -->
            <b-form-group
              label="Contraseña"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="contraseña"
                vid="Password"
                rules="password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                    :disabled="emailChanged"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- #endregion::Password input -->

          <!-- #region::CompanyUsers save button -->
          <b-col md="6">
            <b-button
              variant="principal-btn"
              class="my-1 my-md-0 w-100 principal-btn"
              @click="onSaveCompanyUser"
            >
              {{ id ? 'Guardar cambios' : 'Crear usuario' }}
            </b-button>
          </b-col>
          <!-- #endregion::CompanyUsers save button -->

          <!-- #region::Cancel save button -->
          <b-col md="6">
            <b-button
              variant="delete-btn"
              class="my-lg-0 w-100 delete-btn"
              @click="onClose"
            >
              Cancelar
            </b-button>
          </b-col>
          <!-- #endregion::Cancel save button -->

        </b-form-row>
      </b-form>
      <!-- #endregion::Form -->

    </validation-observer>
  </b-container>
</template>

<script>
// #region imports
import { mapActions } from 'vuex'
import {
  required, min, email, digits, emailPrefix,
} from '@validations'
import {
  BContainer, BFormRow, BCol, BForm, BButton, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'

import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import es from 'vee-validate/dist/locale/es.json'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BCol,
    BForm,
    BButton,
    BContainer,
    BFormRow,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
    TextInputWithValidation,
    SelectWithValidation,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      id: null,
      userType: 'Coordinador de Almacen',
      fullName: '',
      userEmail: '',
      phone: '',
      location: '',
      password: '',
      companyUser: null,
      // * 05/10/2022 - TAG: Validations
      required,
      min,
      email,
      digits,
      emailPrefix,
      // * Toggle Password
      password1FieldType: 'password',

      roles: ['Coordinador de Almacen', 'Encargado de Facturacion'],
      locations: ['Bodega GDL', 'Bodega CDMX', 'Tienda'],
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    emailChanged() {
      if (!this.companyUser) {
        return false
      }
      return this.userEmail !== this.companyUser.email.substring(0, this.companyUser.email.indexOf('@'))
    },
    passwordChanged() {
      if (!this.companyUser) {
        return false
      }
      return this.password.length > 0
    },
  },
  async created() {
    localize('es', es)
  },
  methods: {
    ...mapActions({
      createCompanyUser: 'company/createCompanyUser',
      updateCompanyUser: 'company/updateCompanyUser',
    }),
    async onSaveCompanyUser() {
      const success = await this.$refs.companyUserForm.validate()
      if (success) {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        const formData = new FormData()

        if (this.id) {
          formData.append('id', this.id)

          if (this.passwordChanged) {
            formData.append('password', this.password)
          }
        }

        formData.append('userType', this.userType)
        formData.append('name', this.fullName)
        formData.append('email', `${this.userEmail}@optimumequipo.com`)
        formData.append('phone', this.phone)
        formData.append('location', this.location)

        // for (const value of formData.values()) {
        //   console.log(value);
        // }

        try {
          if (this.id) {
            await this.updateCompanyUser(formData)
            this.showSwalFire('¡El usuario ha sido actualizado correctamente!')
          } else {
            await this.createCompanyUser(formData)
            this.showSwalFire('¡El usuario ha sido registrado correctamente!')
          }
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', getError(error), 'danger')
        }
      }
    },
    loadExistingCompanyUser(companyUser) {
      this.companyUser = companyUser
      this.id = companyUser.IdUser
      this.userType = companyUser.Role
      this.fullName = companyUser.Name
      this.userEmail = companyUser.email.substring(0, companyUser.email.indexOf('@'))
      this.phone = companyUser.Phone
      this.location = companyUser.Location
    },
    showSwalFire(message) {
      this.$swal.fire({
        title: 'Guardado',
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.onClose()
          this.$emit('on:reload')
        }
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
    onClose() {
      this.id = null
      this.userType = 'Coordinador de Almacen'
      this.fullName = ''
      this.userEmail = ''
      this.phone = ''
      this.location = ''
      this.password = ''

      this.$refs.companyUserForm.reset()
      this.$emit('on:close')
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
  },
}
</script>
